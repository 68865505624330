@import url("https://use.typekit.net/sob5jke.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "upgrade", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-color: "0B1E4B";
  font-size: "14px";
  font-weight: "300";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
select {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.2917 5.33203C12.711 5.33203 12.9441 5.817 12.6822 6.14438L8.38913 11.5107C8.18897 11.7609 7.80843 11.7609 7.60826 11.5107L3.31524 6.14438C3.05334 5.817 3.28642 5.33203 3.70568 5.33203L12.2917 5.33203Z' fill='%23919AAA'/%3E%3C/svg%3E%0A");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  position: relative;

  z-index: 1;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

thead {
  border-radius: 0;
}

/* Scrollbar for overflow */
::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgb(250, 250, 250);
  box-shadow: inset 1px 2px #ccc;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20rem;
}

/* Scrollbar for overflow */

.animation {
  transition: all 0.5s ease-in-out;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}

.transfer .pincode-pin-input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0 !important;
  /* outline: 2px solid red; */
  display: none;
}

.transfer .each-input {
  width: 100% !important;
  height: 60px !important;
  border-right: 1px solid #d8d8d8;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-triangle {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
}
